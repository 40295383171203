import { inject, Injectable, signal, WritableSignal, } from '@angular/core';
import { AvailabilitySpaBooking } from '../../../../../core/model/availability-spa-booking.model';
import { Credit } from '../../../../../core/model/credit.model';
import { Invoice } from '../../../../../core/model/invoice.model';
import { RendezVous } from '../../../../../core/model/rendez-vous.model';
import { Seance } from '../../../../../core/model/seance.model';
import { FirebaseFunctions } from '../../app.config';

@Injectable({
  providedIn: 'root',
})
/**
 * Cart Service
 */
export class AccountService {
  dataChanged = signal(new Date());
  credits: WritableSignal<{ timeCredit: Credit; session: Credit } | null> = signal(null);
  private functions = inject(FirebaseFunctions);

  async refreshCredits(): Promise<void> {
    const credits = await this.functions.httpsCallable<null, {
      timeCredit: Credit,
      session: Credit
    }>('aquao-credits', null);
    this.credits.set(credits);
  }

  async seances(centerErpId: number, from: string, to: string): Promise<Seance[]> {
    return this.functions.httpsCallable<{
      centerErpId: number,
      from: string,
      to: string
    }, Seance[]>('aquao-seances', { centerErpId, from, to })
      .then(res => res.map(s => new Seance(s)));
  }

  async rendezVous(from: string, to: string): Promise<RendezVous[]> {
    return this.functions.httpsCallable<{
      from: string,
      to: string
    }, RendezVous[]>('aquao-rendezVous', { from, to })
      .then(res => res.map(s => new RendezVous(s)))
      .then(res => {
        res.sort((a, b) => {
          if (a.date === b.date) {
            return b.start.localeCompare(a.start);
          }
          return b.date.localeCompare(a.date);
        })
        return res;
      });
  }

  async invoices(from = 0): Promise<Invoice[]> {
    return this.functions.httpsCallable<{ from: number }, Invoice[]>('aquao-invoices', { from })
      .then(invoices => invoices.map(invoice => new Invoice(invoice)))
  }

  participateSeance(seance: Seance): Promise<void> {
    return this.functions.httpsCallable<{
      erpId: number,
    }, void>('aquao-seanceParticipate', { erpId: seance.erpId });
  }

  waitSeance(seance: Seance, toggleHour: number): Promise<void> {
    return this.functions.httpsCallable<{
      erpId: number, toggleHour: number
    }, void>('aquao-seanceWait', { erpId: seance.erpId, toggleHour });
  }

  cancelWaitSeance(erpId: number): Promise<void> {
    return this.functions.httpsCallable<{
      erpId: number,
    }, void>('aquao-seanceCancelWait', { erpId });
  }

  cancelSeance(erpId: number): Promise<void> {
    return this.functions.httpsCallable<{
      erpId: number,
    }, void>('aquao-seanceCancel', { erpId });
  }

  availabilitySpaBooking(centerErpId: number, erpIds: number[], from: string, to: string
  ): Promise<AvailabilitySpaBooking[]> {
    return this.functions.httpsCallable<{
      centerErpId: number,
      erpIds: number[],
      from: string,
      to: string
    }, AvailabilitySpaBooking[]>('aquao-availabilitySpa', { centerErpId, erpIds, from, to });
  }

  async planSpa(date: string, time: string, userErpId: number, productErpId: number, centerErpId: number,
    simulation: boolean): Promise<string> {
    const { start } = await this.functions.httpsCallable<{
      date: string,
      time: string,
      userErpId: number,
      productErpId: number,
      centerErpId: number,
      simulation: boolean
    }, { start: string }>('aquao-planSpa', {
      date,
      time,
      userErpId,
      productErpId,
      centerErpId,
      simulation,
    });
    return start;
  }
}
