<div class="flex flex-col gap-12 md:flex-row place-content-between relative">
  <div class="grow">
    <div class="h-20 flex items-center border-b border-gray-200 gap-4 mb-5">
      <svg class="fill-100ciels-300 h-10 w-10" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/>
      </svg>
      <p class="text-xl text-100ciels-300 font-semibold">{{ 'nextSessionTitle' | translate }}</p>
    </div>
    @if (updatePlanningProgress()) {
      <div class='w-full'>
        <div class='h-1 w-full bg-gray-300 overflow-hidden'>
          <div class='progress w-full h-full bg-100ciels-300 left-right'></div>
        </div>
      </div>
    } @else {
      @if (availableDays().length) {
        <app-date-picker [customs]="availableDays()" (selectedDataChange)="selectDay($event)"
                         [notBefore]="selectedPeriod.from" [notAfter]="selectedPeriod.to"
                         defaultClasses="line-through"
        ></app-date-picker>
      } @else {
        <p class="text-gray-500 font-semibold">{{ 'noSpaAvailabilities' | translate }}
          <a href="mailto:{{spaEmail()}}" class="font-bold text-100ciels-300">{{ spaEmail() }}</a>.</p>
      }
    }
  </div>
  @if (!updatePlanningProgress()) {
    @if (availableSlots(); as slots) {
      <div #timeSlots>
        <div class="h-20 flex items-center border-b border-gray-200 gap-4 mb-5">
          <p class="text-gray-500 font-semibold">{{ 'selectAnHour' | translate }}</p>
        </div>
        <div class="h-80 overflow-y-auto flex flex-col gap-2">
          @for (slot of slots; track slot) {
            <button (click)="selectSlot(slot)" tabindex="0" onKeyUp (keyup.enter)="emulateClick($event)"
                    [disabled]="selectSlotProgress()"
                    class="flex flex-row cursor-pointer w-100 text-gray-800 text-center font-semibold
                    border-2 border-gray-200 rounded-lg place-content-between p-4 hover:border-100ciels-300
                    hover:bg-100ciels-300 hover:text-white"
                    [ngClass]="{
                    'border-100ciels-300 bg-100ciels-300 text-white': selectedSlot() === slot,
                    'disabled:bg-gray-300 disabled:text-white': selectedSlot() !== slot
                    }">
              <p class="grow w-8/12"> {{ slot }} </p>
              @if (selectSlotProgress() && selectedSlot() === slot) {
                <svg class="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              }
            </button>
          }
        </div>
      </div>
    }
  }
  @if (actualSlot(); as slot) {
    <div class="absolute left-2 right-2 bottom-2 md:top-12 md:bottom-12 md:left-12 md:right-12 m-auto">
      <div class="shadow-2xl shadow-gray-700 rounded-xl bg-white p-4">
        <p class="font-semibold text-gray-800 mb-6">{{ 'reservationFor' | translate }} :</p>
        <div class="flex gap-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-10 h-10 fill-gray-500">
            <path
              d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/>
          </svg>
          <p><span class="font-semibold">{{ 'product/' + product().id + '/name' | translate }}</span>
            <br>
            {{ selectedDay()?.date | date }}
            <br>
            {{ 'at' | translate }} {{ slot }}
          </p>
        </div>
        <div class="px-6 flex gap-4 mt-4">
          <button class="grow py-3 main-btn bg-gray-400 hover:bg-gray-300" (click)="actualSlot.set(null)">
            {{ 'cancel' | translate }}
          </button>
          <button class="grow py-3 main-btn"
                  appHoverGradient [appProgressClick]="planSpa">
            {{ 'validate' | translate }}
          </button>
        </div>
      </div>
    </div>
  }
</div>
