import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  output,
  signal,
  viewChild
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { AvailabilitySpaBooking } from '../../../../../../core/model/availability-spa-booking.model';
import { SignInComponent } from '../../../account/sign-in/sign-in.component';
import { emulateClick } from '../../../core/a11y';
import { DrawerService } from '../../../drawer/drawer.service';
import { NotificationService } from '../../../notification/notification.service';
import { Custom, DatePickerComponent } from '../../date-picker/date-picker.component';
import { HoverGradientDirective } from '../../directives/hover-gradient.directive';
import { ProgressClickDirective } from '../../directives/progress-click.directive';
import { Center } from '../../model/center.model';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { Product } from '../../model/product.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-spa-booking',
  templateUrl: 'spa-booking.component.html',
  imports: [
    HoverGradientDirective,
    DatePickerComponent,
    NgxTolgeeModule,
    NgClass,
    ReactiveFormsModule,
    DatePipe,
    ProgressClickDirective
  ]
})
export class SpaBookingComponent {
  accountService = inject(AccountService);
  authService = inject(AuthService);
  drawerService = inject(DrawerService);
  notificationService = inject(NotificationService);
  center = input.required<Center>();
  product = input.required<Product>();
  validated = output<{
    date: string,
    time: string,
    productId: number,
    created: string
  }>();
  availableDays = signal<Custom<Date>[]>([]);
  selectedDay = signal<AvailabilitySpaBooking | null>(null);
  availableSlots = computed(() => {
    const selectedDay = this.selectedDay();
    if (!selectedDay) {
      return null;
    }
    const slots: Date[] = []
    // for (const slot of selectedDay.slots) {
    //   const [hour, minute] = slot.from.split(':').map(Number);
    //   const [maxHour, maxMinute] = slot.to.split(':').map(Number);
    //   const start = Date.UTC(1970, 0, 1, hour, Math.ceil(minute / 15) * 15, 0)
    //   const max = new Date(Date.UTC(1970, 0, 1, maxHour, maxMinute + 1, 0));
    //   const time = new Date(start);
    //   while (time.getTime() + selectedSpaItem.duration * 60 * 1000 <= max.getTime()) {
    //     slots.push(new Date(time));
    //     time.setUTCMinutes(time.getUTCMinutes() + 15);
    //   }
    // }
    slots.sort((a, b) => a.getTime() - b.getTime());
    return slots.map(date => {
      const hour = date.getUTCHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
      const minute = date.getUTCMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
      return `${hour}:${minute}`;
    })
  });
  selectedSlot = signal<string | null>(null);
  actualSlot = signal<string | null>(null);
  selectSlotProgress = signal(false);
  updatePlanningProgress = signal(false);
  selectedPeriod: { from: Date, to: Date } = ((): { from: Date, to: Date } => {
    const from = new Date();
    from.setDate(from.getDate() + 2);
    const to = new Date()
    to.setMonth(to.getMonth() + 2);
    return { from, to };
  })();
  spaEmail = computed(() => {
    const center = this.center();
    switch (center.erpId) {
    case 1:
      return 'XXXXXX';
    case 2:
      return 'XXXXXX';
    case 3:
      return 'XXXXXX';
    case 4:
      return 'XXXXXX';
    case 5:
      return 'XXXXXX';
    case 6:
      return 'XXXXXX';
    case 7:
      return 'XXXXXX';
    case 8:
      return 'XXXXXX';
    case 9:
      return 'XXXXXX';
    default:
      return 'XXXXXX';
    }
  });
  timeSlotsView = viewChild<ElementRef<HTMLElement>>('timeSlots');
  protected readonly emulateClick = emulateClick;

  constructor() {
    effect(async () => {
      const product = this.product();
      await this.updatePlanning(product.linkedProducts.map(p => p.erpId));
    });

    effect(() => {
      const target = this.timeSlotsView()?.nativeElement;
      if (!this.availableSlots()?.length || !target) {
        return;
      }
      // Target is outside the viewport from the bottom
      if (target.getBoundingClientRect().bottom > window.innerHeight) {
        target.scrollIntoView(false);
      }
      // Target is outside the view from the top
      if (target.getBoundingClientRect().top < 0) {
        target.scrollIntoView();
      }

    });
    effect(async () => {
      const user = this.authService.user();
      // const center = this.center();
      const selectedDay = this.selectedDay();
      const selectedSlot = this.selectedSlot();
      if (!user?.authCompleted || !user.erpId || !selectedDay || !selectedSlot) {
        this.actualSlot.set(null)
        return;
      }
      try {
        // const actualSlot = await this.accountService.planSpa(selectedDay.date, selectedSlot, user.erpId,
        //   selectedSpaItem.erpId, spaBooking.erpId, spaBooking.type, center.erpId, true);
        // this.actualSlot.set(actualSlot);
      } catch (err) {
        this.notificationService.open({
          type: 'error',
          title: 'cannotPlan',
          message: 'cannotPlanDescription'
        });
      } finally {
        this.selectSlotProgress.set(false);
      }
    }, { allowSignalWrites: true });
  }

  planSpa = async (): Promise<void> => {
    /*
    const selectedSpaItem = this.selectedSpaItem();
    const selectedDay = this.selectedDay();
    const actualSlot = this.actualSlot();
    if (!selectedSpaItem || !selectedDay || !actualSlot) {
      return;
    }
    this.validated.emit({
      date: selectedDay.date,
      time: actualSlot,
      productId: selectedSpaItem.erpId,
      created: new Date().toISOString()
    });
    this.actualSlot.set(null)
     */
  }

  selectDay($event: unknown): void {
    this.selectedDay.set($event as AvailabilitySpaBooking);
    this.selectedSlot.set(null);
    this.actualSlot.set(null);
  }

  /**
   * New time slot selected by the user
   * @param slot The time slot
   */
  selectSlot(slot: string): void {
    if (!this.authService.user()?.authCompleted) {
      this.drawerService.open({ component: SignInComponent, inputs: { inDrawer: true }, style: 'transparent' });
    }
    this.selectedSlot.set(null);
    this.selectSlotProgress.set(true);
    this.selectedSlot.set(slot);
    this.actualSlot.set(null);
  }

  /**
   * Display planning availabilities
   * @param erpIds Ids of all prestations to plan
   */
  private async updatePlanning(erpIds: number[]): Promise<void> {
    this.updatePlanningProgress.set(true);
    const availabilities = await this.accountService.availabilitySpaBooking(
      this.center().erpId,
      erpIds,
      this.selectedPeriod.from.toISOString().slice(0, 10),
      this.selectedPeriod.to.toISOString().slice(0, 10)
    );
    console.log(availabilities);
    //const availableDays = availabilities.map(a => ({
    //  from: new Date(`${a.date}T00:00:00Z`),
    //  to: new Date(`${a.date}T00:00:00Z`),
    //  css: ['border', '!text-white', '!bg-100ciels-300'],
    //  data: a
    //} as Custom<Date>));
    //this.availableDays.set(availableDays);
    this.updatePlanningProgress.set(false);
  }
}
