<footer class="bg-100ciels-600 mb-20 md:mb-0">
  <div class="layout py-20">
    <div class="py-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 md:gap-6 px-8 lg:px-0">
      <div class="pt-8 pb-2 md:mt-12 xl:mt-0 border-b border-gray-200 md:border-none">
        <p (click)="open('centers')"
           (keyup.enter)="emulateClick($event)"
           class="text-white md:cursor-default font-bold pb-6 flex justify-between"
           role="button"
           tabindex="0">{{ 'cities' | translate }}
          <img [ngClass]="{'rotate-180': opened === 'centers'}" alt="" class="h-4 w-4 md:hidden" height="60"
               ngSrc="assets/icons/dark/down.svg"
               width="60">
        </p>
        <ul
          [ngClass]="opened === 'centers' ? 'block' : 'hidden md:block'">
          @for (center of centerService.centers(); track center.id) {
            <li class="mb-3">
              <a
                [href]="center.url"
                (click)="navigationService.goTo(center)"
                (keyup.enter)="emulateClick($event)"
                class="text-white py-1 hover:text-100ciels-300 text-left">
                {{ center.name }} - {{ center['city'] }}
              </a>
            </li>
          }
        </ul>
      </div>
      <div class="pt-8 pb-2 md:mt-12 xl:mt-0 border-b border-gray-200 md:border-none">
        <p (click)="open('activities')"
           (keyup.enter)="emulateClick($event)"
           class="font-bold text-white pb-6 flex justify-between"
           role="button"
           tabindex="0">{{ 'activities' | translate }}
          <img [ngClass]="{'rotate-180': opened === 'activities'}" alt="" class="h-4 w-4 md:hidden" height="60"
               ngSrc="assets/icons/dark/down.svg"
               width="60">
        </p>
        <ul [ngClass]="opened === 'activities' ? 'block' : 'hidden md:block'">
          @for (universe of universeService.universes(); track universe.id) {
            @for (activity of universe.activities; track activity.id) {
              <li class="mb-3">
                <a routerLink="/univers" [fragment]="activity.name"
                   class="text-white py-1 hover:text-100ciels-300"> {{ 'universe/' + universe.id + '/activity/' + activity.id + '/name' | translate }}</a>
              </li>
            }
          }
        </ul>
      </div>
      <div class="pt-8 pb-2 md:mt-12 xl:mt-0 border-b border-gray-200 md:border-none">
        <p (click)="open('offer')"
           (keyup.enter)="emulateClick($event)"
           class="font-bold text-white pb-6 flex justify-between"
           role="button"
           tabindex="0">{{ 'offer' | translate }}
          <img [ngClass]="{'rotate-180': opened === 'offer' }" alt="" class="h-4 w-4 md:hidden" height="60"
               ngSrc="assets/icons/dark/down.svg"
               width="60">
        </p>
        <ul [ngClass]="opened === 'offer' ? 'block' : 'hidden md:block'">
          <li class="mb-3">
            <a [queryParams]="{gift: true}" class="text-white py-1 hover:text-100ciels-300"
               routerLink="/offers">{{ 'giftVouchers' | translate }}
            </a>
          </li>
          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300" routerLink="/vouchers">{{ 'giftCard' | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div class="pt-8 pb-2 md:mt-12 xl:mt-0 border-b border-gray-200 md:border-none">
        <p (click)="open('customer')"
           (keyup.enter)="emulateClick($event)"
           class="font-bold text-white pb-6 flex justify-between"
           role="button"
           tabindex="0">{{ 'customerRelationship' | translate }}
          <img [ngClass]="{'rotate-180': opened === 'customer' }" alt="" class="h-4 w-4 md:hidden" height="60"
               ngSrc="assets/icons/dark/down.svg"
               width="60">
        </p>
        <ul [ngClass]="opened === 'customer' ? 'block' : 'hidden md:block'">
          <li class="mb-3">
            <button
              (click)="authService.user()?.authCompleted ? null : drawerService.open({component: SignInComponent, inputs: { inDrawer: true }, style: 'transparent'})"
              [routerLink]="authService.user()?.authCompleted ? '/account' : null"
              class="text-white hover:text-100ciels-300">{{ 'auth' | translate }}
            </button>
          </li>

          <li class="mb-3">
            <button
              (click)="authService.user()?.authCompleted ? null : drawerService.open({component: SignInComponent, inputs: { inDrawer: true }, style: 'transparent'})"
              [routerLink]="authService.user()?.authCompleted ? '/account' : null"
              class="text-white py-1 hover:text-100ciels-300">{{ 'personalData' | translate }}
            </button>
          </li>

          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300" fragment="contact"
               routerLink="/">{{ 'contactUs' | translate }}
            </a>
          </li>
          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300" routerLink="/help">{{ 'helpCenter' | translate }}
            </a></li>
          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300" routerLink="/cse">{{ 'cse' | translate }}
            </a>
          </li>
          <li class="mt-8 mb-6 hidden">
            <a href="https://www.hammam-lescentciels.com/">
              <img alt="{{ 'downloadAppstore' | translate }}" height="40" ngSrc="assets/images/badge-appstore.svg"
                   width="128">
            </a>
            <a href="https://www.hammam-lescentciels.com/">
              <img alt="{{ 'downloadGooglePlay' | translate }}" class="mt-3" height="37"
                   ngSrc="assets/images/badge-googleplay.svg"
                   width="128">
            </a>
          </li>
        </ul>
      </div>
      <div class="pt-8 pb-2 md:mt-12 xl:mt-0 ">
        <p (click)="open('les100ciels')"
           (keyup.enter)="emulateClick($event)"
           class="font-bold text-white pb-6 flex justify-between"
           role="button"
           tabindex="0">{{ 'les100cielsCenter' | translate }}
          <img [ngClass]="{'rotate-180': opened === 'les100ciels'}" alt="" class="h-4 w-4 md:hidden" height="60"
               ngSrc="assets/icons/dark/down.svg"
               width="60">
        </p>
        <ul [ngClass]="opened === 'les100ciels' ? 'block' : 'hidden md:block'">
          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300"
               href="https://www.hammam-lescentciels.com/">{{ 'lesCentCiels' | translate }}
            </a>
          </li>
          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300"
               routerLink="/a-propos">{{ 'theSociety' | translate }}
            </a>
          </li>
          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300"
               href="https://www.hammam-lescentciels.com">hammam-lescentciels.com
            </a>
          </li>
          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300" routerLink="/careers">{{ 'careers' | translate }}
            </a>
          </li>
          <li class="mb-3">
            <a class="text-white py-1 hover:text-100ciels-300" routerLink="/press">{{ 'pressAccess' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <hr class="h-2 my-5 md:my-12 mx-8 lg:mx-0">
    <div class="lg:flex justify-between items-center px-8 lg:px-0">
      <p class="text-white text-sm ">©
        <span>
          {{ 'les100ciels' | translate }} {{ currentYear.toString() }}
        </span>

        @for (page of displayedPages(); track page.id) {
          | <a
            class="hover:text-100ciels-300"
            [routerLink]="['/', page.id]">{{ page.id | translate }}</a>
        }
        | <a
          class="hover:text-100ciels-300"
          href="javascript:openAxeptioCookies()">{{ 'updateCookieSettings' | translate }}</a>
      </p>
      <div class="mt-12 md:mt-0 flex items-center gap-4">
        <app-socials/>
        <app-lang-selector/>
      </div>
    </div>
  </div>
</footer>
